import React from 'react';

interface VerticalImageProps {
  source: string;
  sourceMobile: string;
  alt: string;
  link: string;
}

const VerticalImage = ({
  source,
  sourceMobile,
  alt,
  link,
}: VerticalImageProps): JSX.Element => (
  <a href={link} target="_blank" rel="noreferrer">
    <picture>
      <source
        media="(max-width: 500px)"
        srcSet={`${sourceMobile} 500w`}
        sizes="500px"
      />
      <source srcSet={`${source} 1280w`} sizes="1280px" />
      <img
        src={source}
        alt={alt}
        className="drop-shadow rounded-[10px] hover:cursor-pointer active:opacity-50"
      />
    </picture>
  </a>
);

export default VerticalImage;
