import React from 'react';

interface ContentProps {
  children?: React.ReactNode;
}

const Content: React.FC<ContentProps> = ({ children }) => (
  <div className="mx-auto px-6 xl:px-0 xl:w-inner lg:my-24 my-6 text-[17px]">
    {children}
  </div>
);

Content.defaultProps = {
  children: null,
};

export default Content;
